import Public from '@/components/public/Public';
//import Home from '@/components/public/Home';
//import Register from '@/components/public/registration/Register.COM.AU';
//import AgencyRegistration from '@/components/public/registration/AgencyRegistration.COM.AU';
import Login from '@/components/login';
import RecoverPassword from '@/components/public/RecoverPassword';
import ResetPassword from '@/components/public/ResetPassword';
//import ThankyouRegister from '@/components/public/registration/ThankYouRegister';
//import ThankyouAgencyRegistration from '@/components/public/registration/ThankYouAgencyRegistration';
import VerifiedLogin from '@/components/public/VerifiedLogin';
//import ThankyouContact from '@/components/public/ThankYouContact';
//import PrivacyPolicy from '@/components/public/PrivacyPolicy';
//import TermsConditions from '@/components/public/TermsConditions';
//import WebsiteTerms from '@/components/public/WebsiteTerms';
//import Sustainability from '@/components/public/SustainabilityPolicy';

export const publicRoutes = [
  {
    path: '/',
    component: Public,
    children: [
      /*
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          title: 'ANZCRO | The Australian and New Zealand Holiday Experts',
          description:
            'ANZCRO - The Australian and New Zealand Holiday Experts. Send us an email or contact our reservations office on the telephone numbers provided.',
        },
      },
      {
        path: 'register/',
        name: 'register',
        component: Register,
      },
      {
        path: 'new-agency-registration/',
        name: 'agencyregistration',
        component: AgencyRegistration,
      },
      {
        path: 'agent-account-requested/',
        name: 'thankyouRegister',
        component: ThankyouRegister,
      },
      {
        path: 'agency-registration-requested/',
        name: 'thankyouAgencyRegistration',
        component: ThankyouAgencyRegistration,
      },*/
      {
        path: '',
        name: 'login',
        component: Login,
        meta: {
          title: 'ANZCRO Connect | Log in',
          description:
            'ANZCRO Connect - The online self-service booking platform that makes booking your next Australian, New Zealand or South Pacific itinerary a snap.',
        },
      },
      /*
      {
        path: 'terms-and-conditions/',
        name: 'termsConditions',
        component: TermsConditions,
      },
      {
        path: 'website-terms/',
        name: 'websiteTerms',
        component: WebsiteTerms,
      },
      {
        path: 'enquiry-received/',
        name: 'thankyouContact',
        component: ThankyouContact,
      },
      {
        path: 'privacy-policy/',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
      },
      {
        path: 'sustainability-policy/',
        name: 'sustainability',
        component: Sustainability,
      },*/
      {
        path: 'verified-login/',
        name: 'verifiedLogin',
        component: VerifiedLogin,
      },
      {
        path: 'recover-password/',
        name: 'recoverPassword',
        component: RecoverPassword,
      },
      {
        path: 'reset-password/',
        name: 'resetPassword',
        component: ResetPassword,
      },
    ],
  },
];
