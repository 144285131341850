import { render, staticRenderFns } from "./PublicHeader.vue?vue&type=template&id=23de31da"
import script from "./PublicHeader.vue?vue&type=script&lang=js"
export * from "./PublicHeader.vue?vue&type=script&lang=js"
import style0 from "./PublicHeader.vue?vue&type=style&index=0&id=23de31da&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarItems } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VDivider,
  VImg,
  VSpacer,
  VToolbar,
  VToolbarItems,
})
