<template>
  <v-container pa-0 fluid class="ao-login">
    <v-layout row fill-height class="hero-waves hero-login">
      <v-flex>
        <!--
        <v-img
          :src="require(`@/assets/img/heros/${randomHero.source}`)"
          gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.65) 50%, rgba(22,22,22,1) 100%"
          class="login-hero"
          position="top center"
        >-->
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center py-5>
            <v-flex xs12 sm6 offset-sm3 md4 offset-md4 text-xs-center>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro-connect-inverted.svg"
                width="160"
                class="my-4 d-inline-block"
              ></v-img>
              <v-card
                dark
                :color="midnightBlue"
                class="pa-4 elevation-0 card-with-border dash-rounded"
              >
                <v-alert :value="true" type="error" v-if="errorMsg.length > 0">{{errorMsg}}</v-alert>
                <v-form ref="loginForm">
                  <v-layout row>
                    <v-flex xs12>
                      <v-text-field
                        v-model="username"
                        box
                        :rules="usernameRules"
                        label="Username / Email"
                        :disabled="authenticating"
                        :color="kiwiGreen"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row>
                    <v-flex xs12>
                      <v-text-field
                        v-model="password"
                        box
                        :rules="passwordRules"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'"
                        :type="show1 ? 'text' : 'password'"
                        name="password"
                        label="Password"
                        @click:append="show1 = !show1"
                        :disabled="authenticating"
                        :color="kiwiGreen"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-btn
                        type="submit"
                        large
                        dark
                        block
                        :color="kiwiGreenB1"
                        class="elevation-0 kiwi-btn"
                        @click.prevent="login()"
                        :loading="authenticating"
                        :disabled="authenticating"
                      >Log In</v-btn>
                    </v-flex>

                    <v-flex xs12 text-xs-left>
                      <p class="caption mt-3 mb-0">
                        Not registered yet?
                        <a :href="registerLink" class="kiwiGreen mx-1">Register Now</a>|
                        <router-link to="/recover-password/" class="kiwiGreen mx-1">Lost Password</router-link>
                      </p>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        <!--
        </v-img>
        -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { tempData } from '@/services/route.service';
import { isValidRedirectPath } from '@/util-modules/redirect';
export default {
  data() {
    return {
      show1: false,
      username: '',
      password: '',
      authenticating: false,
      usernameRules: [v => !!v || 'Username is required'],
      passwordRules: [v => !!v || 'Password is required'],
      errorMsg: '',
      redirectTo: {
        name: 'dashboardhome',
      },
      prevRoute: null,
      heros: [
        {
          source: 'hero-te-puia.jpg',
          caption: 'Maori Cultural Experience - Te Puia, Rotorua',
        },
        {
          source: 'hero-glowworms.jpg',
          caption: 'Waitomo Glowworm Caves',
        },
        {
          source: 'hero-ski.jpg',
          caption: 'Ski Holiday - Cardrona Alpine Resort',
        },
        {
          source: 'hero-forest.jpg',
          caption: 'The Redwoods - Rotorua',
        },
      ],
    };
  },
  created() {
    if (this.$store.state.user) {
      this.$router.push(this.redirectTo);
    }
  },
  computed: {
    randomHero() {
      return this.heros[Math.floor(Math.random() * this.heros.length)];
    },
    registerLink() {
      return `${this.$config.WP_URL}/register/`;
    },
  },
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
    if (this.$route.query.r && isValidRedirectPath(this.$route.query.r)) {
      const url = new URL('https://www.anzcro.com.au' + this.$route.query.r);
      const parms = new URLSearchParams(url.searchParams);
      let query = {};
      for (var pair of parms.entries()) {
        query[pair[0]] = pair[1];
      }
      this.redirectTo = {
        path: url.pathname,
        query: query,
      };
    }
  },
  methods: {
    async login() {
      this.authenticating = true;
      if (!this.$refs.loginForm.validate()) {
        this.authenticating = false;
        return;
      }

      try {
        await this.$store.dispatch(
          'login',
          {
            username: this.username.trim(),
            password: this.password.trim(),
          }
        );
        await this.$store.dispatch('loadUser');
        tempData.add({
          timespan: Date.now(),
          action: 'after_login_to_dashboard',
        });
        this.$router.pushAsync(this.redirectTo);
        // this.username = '';
        // this.password = '';
      } catch (reason) {
        this.errorMsg = reason;
      } finally {
        this.authenticating = false;
      }
    },
  },
  watch: {
    username() {
      this.errorMsg = '';
    },
    password() {
      this.errorMsg = '';
    },
  },
};
</script>

<style>
.ao-login {
  height: auto;
  max-height: 100vh;
}
.login-hero {
  height: auto;
  min-height: 100vh;
}
</style>
