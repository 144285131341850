<template>
  <v-footer height="100%" dark :color="midnightBlue" class="public-footer align-start">
    <v-layout row>
      <v-flex>
        <v-container fluid pa-0>
          <v-card dark flat tile class="ma-0 pa-0" :color="midnightBlue">
            <!--
            <v-layout row mb-3>
              <v-flex>
                <v-container>
                  <v-layout row wrap>
                    <v-flex xs12 sm8 order-sm1 lg4 order-lg1>
                      <v-layout row wrap my-3>
                        <v-flex xs12 class="footer-section-title anzcro" mb-2>
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro_logo-white.svg"
                            width="107"
                            height="26"
                            class="mb-2"
                          ></v-img>
                        </v-flex>
                        <v-flex xs12 py-2>
                          <v-icon size="17" class="mr-2" :color="kiwiGreen">phone</v-icon>1300 366 966
                        </v-flex>
                        <v-flex xs12 py-2>
                          <v-icon size="17" class="mr-2" :color="kiwiGreen">location_city</v-icon>Level 1, 6 Short Street, Southport, QLD
                        </v-flex>
                        <v-flex xs12 py-2>
                          <v-icon size="17" class="mr-2" :color="kiwiGreen">alternate_email</v-icon>info@anzcro.com.au
                        </v-flex>
                        <v-flex xs12 py-2>
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2024/02/footer-logos-4.png"
                            width="280"
                            height="90"
                            class="mt-3 mb-4"
                            alt="National Travel Industry Awards Winner 2023, ATEC, AITA, CATO, ATAS and IATA logos"
                          ></v-img>
                          <v-img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/footer-logos-2.png"
                            width="277"
                            height="30"
                            class="mt-1 mb-2"
                          ></v-img>
                          <small
                            class="caption grey--text d-block mt-4"
                          >IATA 02352033 | AFTA 9981<br/>ATAS A11024 | CATO 45818260</small>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 order-sm3 lg5 order-lg2>
                      <v-layout row wrap my-3>
                        <v-flex xs12 class="footer-section-title" mb-2>
                          <span class="subheading grey--text">Links</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/">Home</a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/deals/">Deals</a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/register/">Get an Account</a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/new-agency-registration/">New Agency Registration</a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a
                                :href="$paymentUrl"
                                target="_blank"
                              >
                                Payments
                              </a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/privacy-policy/">Privacy Policy</a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/terms-and-conditions/">Terms & Conditions</a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/website-terms/">Website Terms</a>
                            </v-flex>
                            <v-flex xs12 sm4 lg6 py-2>
                              <v-icon size="17" class="mr-2" :color="kiwiGreen">arrow_right</v-icon>
                              <a href="/sustainability-policy/">Sustainability Policy</a>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            -->
            <v-layout row>
              <v-flex>
                <v-container py-4>
                  <v-layout row>
                    <v-flex text-xs-center>
                      <span
                        class="caption grey--text"
                      >© ANZCRO 2024 - Australia New Zealand Central Reservations Office</span>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {};
</script>

<style>
.public-footer a {
  color: #ffffff;
  text-decoration: none;
}
.footer-section-title {
  min-height: 36px;
}
</style>
