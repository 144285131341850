const Dashboard = () => import('@/components/dashboard/Dashboard.vue');
const DashboardHome = () => import('@/components/dashboard/DashboardHome.vue');
const DashboardQuotes = () => import('@/components/dashboard/DashboardQuotes.vue');
const DashboardBookings = () => import('@/components/dashboard/DashboardBookings.vue');
const DashboardNotifications = () => import('@/components/dashboard/DashboardNotifications.vue');
const DashboardAccount = () => import('@/components/dashboard/account/DashboardAccount.vue');
const DashboardPreferences = () => import('@/components/dashboard/DashboardPreferences.vue');
const DashboardAgents = () => import('@/components/dashboard/agents/DashboardAgents.vue');
const DashboardRequests = () => import('@/components/dashboard/requests/DashboardRequests.vue');
const DashboardQuote = () => import('@/components/dashboard/DashboardQuote.vue');
const DashboardBooking = () => import('@/components/dashboard/DashboardBooking.vue');
const DashboardHelp = () => import('@/components/dashboard/help/DashboardHelp.vue');
const DashboardHelpGuideContent = () =>
  import('@/components/dashboard/help/DashboardHelpGuideContent.vue');
const DashboardHelpGuide = () => import('@/components/dashboard/help/DashboardHelpGuide.vue');
const DashboardHelpVideos = () => import('@/components/dashboard/videos/DashboardHelpVideos.vue');
const DashboardVideoGettingStarted = () => import('@/components/dashboard/videos/VideoGettingStarted.vue');

const ItineraryBuilderSection = () =>
  import('@/components/dashboard/help/ItineraryBuilderSection.vue');
const DashboardSection = () => import('@/components/dashboard/help/DashboardSection.vue');

const DashboardPayments = () => import('@/components/dashboard/DashboardPayments.vue');

export const dashboardRoutes = [
  {
    path: '/dashboard/',
    component: Dashboard,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'home/',
        name: 'dashboardhome',
        component: DashboardHome,
      },
      {
        path: 'quotes/',
        name: 'dashboardquotes',
        component: DashboardQuotes,
      },
      {
        path: 'quote/:id',
        name: 'dashboard-quote',
        component: DashboardQuote,
      },
      {
        path: 'bookings/',
        name: 'dashboardbookings',
        component: DashboardBookings,
      },
      {
        path: 'booking/:booking',
        name: 'dashboardbooking',
        component: DashboardBooking,
      },
      {
        path: 'notifications/',
        name: 'dashboardnotifications',
        component: DashboardNotifications,
      },
      {
        path: 'account/',
        name: 'dashboardaccount',
        component: DashboardAccount,
      },
      {
        path: 'preferences/',
        name: 'dashboardpreferences',
        component: DashboardPreferences,
      },
      {
        path: 'agents/',
        name: 'dashboardagents',
        component: DashboardAgents,
      },
      {
        path: 'requests/',
        name: 'dashboardrequests',
        component: DashboardRequests,
      },
      {
        path: 'help/',
        name: 'dashboardhelp',
        component: DashboardHelp,
      },
      {
        path: 'help/guide/',
        name: 'dashboardhelpguide',
        component: DashboardHelpGuide,
        children: [
          {
            path: '',
            component: DashboardHelpGuideContent,
          },
          {
            path: 'itinerary-builder/:section/:child/',
            component: ItineraryBuilderSection,
          },
          {
            path: ':section/:child/',
            component: DashboardSection,
          },
        ],
      },
      {
        path: 'help/videos/',
        name: 'dashboardhelpvideos',
        component: DashboardHelpVideos,
      },
      {
        path: 'help/videos/getting-started/',
        name: 'dashboardhelpvideogetstarted',
        component: DashboardVideoGettingStarted,
      },
      {
        path: 'payments/result/',
        name: 'paymentResult',
        component: DashboardPayments
      }
    ],
  },
];
