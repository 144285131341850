<template>
  <v-toolbar app fixed height="48" id="mainheader" class="py-3 public-header elevation-0">
    <a :href="$config.WP_URL">
        <v-img src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro_logo-white.svg" class="mb-2 logo-header" alt="ANZCRO"></v-img>
    </a>

    <v-divider inset vertical class="mx-4 nz-experts-divider hidden-xs-only hidden-md-only hidden-lg-only"></v-divider>

    <v-img contain src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/the-australian-new-zealand-holiday-experts-stacked.svg" alt="The Australian and New Zealand Holiday Experts" class="nz-experts hidden-xs-only hidden-md-only hidden-lg-only"></v-img>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <v-btn v-if="isAuth" dark :color="kiwiGreen" to="/dashboard/home/" class="elevation-0">Dashboard</v-btn>
      <v-btn v-if="!isAuth" flat outline class="white--text elevation-0" to="/">Login</v-btn>
      <!--<v-btn flat dark icon large class="ml-3 hidden-md-and-up" @click="toogleMenu">
        <v-icon>menu</v-icon>
      </v-btn>-->
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  computed: {
    isAuth() {
      return !!this.$store.state.user;
    },
  },
  methods: {
    handleSCroll() {
      let header = document.querySelector('.v-toolbar');
      if (window.scrollY > 100 && !header.className.includes('v-toolbar--bgchange')) {
        header.classList.add('v-toolbar--bgchange', 'elevation-1');
      } else if (window.scrollY < 100) {
        header.classList.remove('v-toolbar--bgchange', 'elevation-1');
      }
    },
    toogleMenu() {
      this.$emit('toogleMenu');
    },
  },
  created() {
    window.addEventListener('scroll', this.handleSCroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleSCroll);
  },
};
</script>

<style>
.public-header.elevation-0.elevation-1{
  box-shadow:none !important;
}
.nz-experts{
  display:block;
  width:224px;
  height:24px;
  flex-grow: 0;
}
.theme--light.v-divider.nz-experts-divider {
    border-color: rgba(255,255,255, 0.24);
}
</style>
